// packages
import * as React from "react"
import Carousel from "react-multi-carousel";
import Header from "./components/header"
import Image from 'react-bootstrap/Image'
import AccordionItem from "./components/AccordionItem"
import AccordionWrapper from "./components/AccordionWrapper"
import Namecard from "./components/namecard"
import { Helmet } from 'react-helmet'
import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  mintNFT,
  roleFetcher,
  totalMintNumber,
  calculatePrice,
  totalAcctMintNumber,
  isRightNetwork,
} from "./util/interact.js";
import Countdown from "react-countdown";
import Flip from "./util/Flip";
import {mintLimit, earlyReleastTs, releastTs, team_ppl, faqs, responsive, projectTotals} from "./components/Constants"

// images
import theme_img from './images/theme.png'
import theme_img2 from './images/theme2.png'
import exp_img1 from './images/example1.png'
import exp_img2 from './images/example2.png'
import exp_img3 from './images/example3.png'
import exp_img4 from './images/example4.png'
import exp_img5 from './images/example5.png'
import exp_img6 from './images/example6.png'
import EZ_img from './images/namecard/EZ.jpg'
import Katsu_img from './images/namecard/Katsu.jpg'
import Nanla_img from './images/namecard/Nanla.jpg'
import Tendies_img from './images/namecard/Tendies.jpg'
import Toro_img from './images/namecard/Toro.jpg'
import Wagyu_img from './images/namecard/Wagyu.jpg'
import Steven_img from './images/namecard/Steven.jpg'
import roadmap_main_img from './images/roadmap_main.png'
import roadmap_footer_img from './images/roadmap_footer.png'
import background_img from './images/background.jpg'

// styles
import "react-multi-carousel/lib/styles.css";
import "./styles/Accordian.css"
import "./styles/App.css"

function App() {
  const [walletAddress, setWallet] = useState("")
  const [status, setStatus] = useState("")
  const [minting, setMinting] = useState(false)
  const [inputMintCount, setInputMintCount] = useState(0)
  const [role, setRole] = useState("")
  const [mintedNum, setMintedNum] = useState(0)
  const [quota, setQuota] = useState(4)
  const [correctNetwork, setCorrectNetwork] = useState(true)

  useEffect(async () => {
    async function updateState() {
      const { address, res } = await getCurrentWalletConnected()
      setWallet(address)
      if (res && res.length > 0) {
        setStatus(res)
      } else  {
        const networkCheck = await isRightNetwork()
        setCorrectNetwork(networkCheck)
  
        if (address.length > 0 && networkCheck) {
          const role = await roleFetcher(address)
          setRole(role)
          const acctMintedNum = await totalAcctMintNumber(address)
          setQuota(mintLimit[role] - acctMintedNum)
        }
      }
    }
    updateState()

    const id = setInterval(() => {
      totalMintNumber().then(mintedNum => {
        if (mintedNum.success) setMintedNum(mintedNum.cnt)
      })
    }, 5000)

    addWalletListener()
    addNetworkListener()
    return () => {
      clearInterval(id)
    }
  }, [walletAddress, minting, role, quota, mintedNum, status, inputMintCount]);

  async function addNetworkListener() {
    if (window.ethereum) {
      window.ethereum.on('chainChanged', (chainId) => window.location.reload())
    } else {
      setWallet("")
      setStatus("Please install 🦊Metamask from https://metamask.io/download.html")
    }
  }

  async function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0])
          setStatus("")
        } else {
          setWallet("")
          setStatus("")
        }
      });
    } else {
      setWallet("")
      setStatus("Please install 🦊Metamask from https://metamask.io/download.html")
    }
  }

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);

    const correctNetwork = await isRightNetwork()
    setCorrectNetwork(correctNetwork)

    if (walletResponse.address.length > 0 && correctNetwork) {
      const role = await roleFetcher(walletResponse.address)
      setRole(role)
      const acctMintedNum = await totalAcctMintNumber(walletResponse.address)
      setQuota(mintLimit[role] - acctMintedNum)
    }
  };

  const onMintPressed = async () => {
    setMinting(true);
    setStatus("Please complete transaction in Metamask");
    const { success, status } = await mintNFT(walletAddress, inputMintCount, role, quota);
    setStatus(status);
    setMinting(false);
  };

  const input_area = (
    <form id="input-area">
      <input
          type="range" 
          id="mintcnt-slider"
          min="0"
          max={quota}
          step="1"
          defaultValue="0"
          onChange={(event) => setInputMintCount(event.target.value)}
      />
      <div id="price-window">
        <div className="mint-state">{"mint: " + inputMintCount }</div>
        {/* <div className="mint-state">{"total: " + calculatePrice(role, inputMintCount).toFixed(3) + " ETH"}</div> */}
        <div className="mint-state">{"!!! FREE !!!"}</div>
      </div>
    </form>
  )

  const getTimerComponent = (title, time) => {
    const days = String(Math.floor(time / (1000 * 60 * 60 * 24))).padStart(2, '0');
    const hours = String(Math.floor((time/ (1000 * 60 * 60)) % 24)).padStart(2, '0');
    const minutes = String(Math.floor((time / 1000 / 60) % 60)).padStart(2, '0');
    const seconds = String(Math.floor((time / 1000) % 60)).padStart(2, '0');
    return (
    <div className="countdown">
      <h3 className="countdown-title">{title}</h3>
      <div className="time-card">
          <div className="flip-cards"><Flip value={days}/></div>
          <div className="flip-cards"><Flip value={hours}/></div>
          <div className="flip-cards"><Flip value={minutes}/></div>
          <div className="flip-cards"><Flip value={seconds}/></div>
      </div>
      <div className="time-card-text">
        <div>days</div>
        <div>hours</div>
        <div>minutes</div>
        <div>seconds</div>
      </div>
    </div>
    )
  }

  const getEarlyCountDown = () => (
    <Countdown
    date={earlyReleastTs*1000}
    intervalDelay={1000}
    precision={1000}
    renderer={props => getTimerComponent("Early release in", props.total)}
    />
  )

  const getPublicCountDown = () => (
      <Countdown
      date={releastTs*1000}
      intervalDelay={0}
      precision={3}
      renderer={props => getTimerComponent("Public release in", props.total)}
      />
  )

  const getReleaseStart = () => (
    <div className="release-start">
      <span className="stroke">Release Started!</span>
      <span className="text" text="Release Started!">Release Started!</span>
    </div>
  )

  const getEarlyReleaseStart = () => (
    <div className="release-start">
      <span className="stroke">Early Release Started!</span>
      <span className="text" text="Early Release Started!">Early Release Started!</span>
    </div>
  )

  const openForEarlyRelease = () => {
    return Math.round(new Date().getTime() / 1000) >= earlyReleastTs
  }

  const openForPublicRelease = () => {
    return Math.round(new Date().getTime() / 1000) >= releastTs
  }

  const getTitle = (role) => {
    if (walletAddress.length === 0) {
      return openForEarlyRelease() ? getEarlyReleaseStart(): getReleaseStart()
    }
    if ((role === 'OG' || role === 'WL') && !openForEarlyRelease()) {
      return getEarlyCountDown()
    } 
    if ((role === "") && !openForPublicRelease()) {
      return getPublicCountDown()
    }
    return getReleaseStart()
  }

  return (
  <div>
    <Helmet
        title="Fatdoods"
        meta={[
          { name: 'description', content: 'Fatdoods NFT' },
          { name: 'keywords', content: 'Fatdoods' },
        ]}
    >
      <html lang="en" />
    </Helmet>
    
    <div
        style={{
          margin: `0 auto`,
          backgroundImage: `url(${background_img})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
        className="site"
    >
      <Header/>
      <div className = "body">
        <br/>
        <div className="front-desk">
          <Image src={theme_img} fluid/>
          <div className="shopping-window">
            <div className="release-state">
              {getTitle(role)}
            </div>
            <div className="project-counter">
                <div className="stroke-solid">{mintedNum} / {projectTotals}</div>
                <div className="text-solid">{mintedNum} / {projectTotals}</div>
            </div>
            <div className="wallet-status">
              <p className="wallet-item">
                <div>wallet: </div>
                <div>{(walletAddress.length > 0 ? walletAddress.substring(0, 8) + "..." : "N/A")}</div>
              </p>
              <p className="wallet-item">
                <div>role:</div>
                <div>{(walletAddress.length > 0 ? (role.length > 0 ? role : "public") : "N/A")}</div>
              </p>
              <p className="wallet-item">
                <div>quota left:</div>
                <div>{(walletAddress.length > 0 ? quota : 'N/A')}</div>
              </p>
            </div>
            <div>
              {walletAddress.length ? input_area : null}
              {walletAddress.length ? (
                <button className="trade-button" disabled={minting || mintedNum == projectTotals || !correctNetwork} id="mintButton" onClick={onMintPressed}>
                  <span>{minting? "Minting" : (mintedNum == projectTotals ? "Sold Out" : "Mint")}</span>
                </button>
              ) : (
                <button className="trade-button" id="connectButton" onClick={connectWalletPressed}>
                  <span>Connect Wallet</span>
                </button>
              )}
              <p id="status-area">{ (status && status.length > 0) ? status : (correctNetwork? null : "❌ Please switch to network to Mainnet first")}</p>
            </div>
          </div>
        </div>
        <div className="introduction" id="introduction">
          <div className="intro-text">
            <div>
            Whether you’re here because of the adorably pudgy, chunky, curvy, pleasantly plump cartoon that has caught your eye or you heard of a new exciting project backed by a passionate team with riveting plans of entering the metaverse with a big FAT bang…introducing FATDOODS! A total of 5,555 unique fatdood NFTs will be available and with challenges and obstacles to beat, each fatdood will embark on its journey of self-discovery and building mental strength surrounded by an encouraging community, motivating one another to fight big systemic issues.
            </div>
            <br/>
            <br/>
            <div>
            Our focus is to create a loyal community of like-minded and supportive fatdoods where we can continuously provide value and execute our ambitious roadmap, including giving back to the organizations and movements that mean the most to us. Community wallet will contribute to anti bullying programs as well as childhood obesity nonprofit charities, Fatdoods holders will be able to vote on which charities they want our community fund to contribute to. Our project was founded on the idea of raising awareness of the health dangers of obesity and advocating well-rounded and sustainable lifestyles with the backbone of a supportive community.
            </div>
            <br/>
            <br/>
            <div>
            Together, fatdoods will venture down their life changing journeys and unlock milestones along the way that will allow them to add rare traits to express themselves in different ways and explore utilities. When all fatdoods are sold out, a handful of 1of1 unique fatdood holders will win and get instantly rewarded with grand prizes such as a Tesla, Rolex watch, a resort trip, ETH giveaways and 1of1 Fatdood airdropped.
            </div>
          </div>
          <Image src={theme_img2} fluid />
        </div>
        <Carousel 
          responsive={responsive} 
          className="showroom" 
          swipeable={true}
          draggable={true}
          showDots={true} 
          infinite={true}
          ssr={true}
          autoPlay={true} 
          autoPlaySpeed={2000} 
          containerClass="carousel-container" 
          shouldResetAutoplay={true}
          partialVisible={false}
        >
          {
            [exp_img1,exp_img2,exp_img3,exp_img4,exp_img5,exp_img6].map(img =>
              <Image className = "example_img" src={img} fluid/>
            )
          }
        </Carousel>
        <div className="section-title" id="roadmap">
          <span className="stroke">Roadmap</span>
          <span className="text" text="Roadmap">Roadmap</span>
        </div>
        <Image src={roadmap_main_img} fluid className="roadmap-img"/>
        <Image src={roadmap_footer_img} fluid className="roadmap-footer"/>
        <div className="section-title" id="team">
          <span className="stroke">Team</span>
          <span className="text" text="Team">Team</span>
        </div>
        <div className="team-members">
          <Namecard 
                name={team_ppl[0].name} 
                title={team_ppl[0].title} 
                description={team_ppl[0].bio} 
                profile_img = {Tendies_img}
          />
          <Namecard 
                name={team_ppl[1].name} 
                title={team_ppl[1].title} 
                description={team_ppl[1].bio} 
                profile_img = {EZ_img}
          />
          <Namecard 
                name={team_ppl[2].name} 
                title={team_ppl[2].title} 
                description={team_ppl[2].bio} 
                profile_img = {Nanla_img}
          />
          <Namecard 
                name={team_ppl[3].name} 
                title={team_ppl[3].title} 
                description={team_ppl[3].bio} 
                profile_img = {Katsu_img}
          />
          <Namecard 
                name={team_ppl[4].name} 
                title={team_ppl[4].title} 
                description={team_ppl[4].bio} 
                profile_img = {Wagyu_img}
          />
          <Namecard 
                name={team_ppl[5].name} 
                title={team_ppl[5].title} 
                description={team_ppl[5].bio} 
                profile_img = {Toro_img}
          />
          <Namecard 
                name={team_ppl[6].name} 
                title={team_ppl[6].title} 
                description={team_ppl[6].bio} 
                profile_img = {Steven_img}
          />
        </div>
        <div className="section-title">
          <span className="stroke">FAQ</span>
          <span className="text" text="FAQ">FAQ</span>
        </div>
        <div className="faq_section">
          <AccordionWrapper>
            {faqs.map((item, index) => (
              <AccordionItem key={index} index={index} title={item.question} description={item.answer} />
            ))}
          </AccordionWrapper>
        </div>
        <div className="bottom_blank">
          <br/>
          <br/>
          <br/>
        </div>{' '}
      </div>
      <footer
            style={{
              marginTop: `0 auto`,
              backgroundColor: `#f6a77d`
            }}
      >
        © {new Date().getFullYear()}, Built by EZ
      </footer>
    </div>
  </div>
  )

}

export default App;