import Web3 from 'web3';
import contractABI from "../contract-abi.json";
import {prices, targetNetworkId, contractAddress, earlyReleastTs, releastTs} from "../components/Constants"

export const calculatePrice = (role, amount) => {
  return amount*prices[role]
}

export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
        address: addressArray[0],
        status: "",
      };
      return obj;
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: "Please install 🦊Metamask from https://metamask.io/download.html"
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      // const addressArray = await window.ethereum.request({
      //   method: "eth_accounts",
      // });
      const web3 = new Web3(Web3.givenProvider || "http://localhost:8545")
      const addressArray = await web3.eth.getAccounts()
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: "",
        };
      } else {
        return {
          address: "",
          status: "",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: "Please install 🦊Metamask from https://metamask.io/download.html"
    };
  }
};

export const isRightNetwork = async () => {
  // const web3 = new Web3(Web3.givenProvider || "http://localhost:7545")
  // const networkId = await web3.eth.net.getId();
  try {
    const chainId = await window.ethereum.request({
      method: "eth_chainId",
    });
    const networkId = parseInt(chainId, 16)
    return checkNetworkId(networkId)
  } catch (error) {
    return false
  }
}

export const checkNetworkId = (networkId) => {
  return networkId === targetNetworkId
}

export const totalMintNumber = async () => {
  const web3 = new Web3(Web3.givenProvider || "http://localhost:7545")

  const networkChecked= await isRightNetwork()
  if (!networkChecked) return {success: false, cnt: 0}
  
  const contract = await new web3.eth.Contract(contractABI, contractAddress);
  try {
    const res = await contract.methods.totalSupply().call()
    return {success: true, cnt:res}
  } catch (error) {
    console.log(error)
    return {success: false, cnt:0}
  }
}

export const roleFetcher = async (address) => {
  if (address.length < 0) {
    return ""
  } else {
    const web3 = new Web3(Web3.givenProvider || "http://localhost:7545")
    const contract = await new web3.eth.Contract(contractABI, contractAddress);
    try {
      const res = await contract.methods.ogWallets(address).call()
      if (res) return "OG"
      const res2 = await contract.methods.wlWallets(address).call()
      if (res2) return "WL" 
      return ""
    } catch (error) {
      console.log(error)
      return ""
    }
  }
}

export const totalAcctMintNumber = async (address) => {
  if (address.length == 0) {
    return 0
  }
  const web3 = new Web3(Web3.givenProvider || "http://localhost:7545")
  const contract = await new web3.eth.Contract(contractABI, contractAddress);
  try {
    const res = await contract.methods.balanceOf(address).call()
    return res
  } catch (error) {
    console.log(error)
    return 0
  }
}

export const mintNFT = async (address, mintNumber, role, quota) => {
  if (mintNumber == 0 || mintNumber > quota) {
    return {
      success: false,
      status: "❌ Invalid mint number",
    };
  }

  // release time check
  const epochSec = Math.round(new Date().getTime() / 1000)
  if (role === 'OG' || role === 'WL') { 
    if (epochSec< earlyReleastTs) return {success:false, status: "Early release not yet"}
  } else {
    if (earlyReleastTs < epochSec && epochSec < releastTs) return {success:false, status: "Still in early release"}
    if (epochSec < earlyReleastTs) return {success:false, status: "Release not yet"}
  }

  const web3 = new Web3(Web3.givenProvider || "http://localhost:8545")
  const contract = await new web3.eth.Contract(contractABI, contractAddress);
  
  const transactionParameters = {
    from: address, // must match user's active address.
    value: web3.utils.toBN(web3.utils.toWei((calculatePrice(role, mintNumber)).toString(),'ether')),
    gasLimit: web3.utils.toHex("300000"),
  };

  try {
    const res = await contract.methods.mint(address, mintNumber).send(transactionParameters)
    console.log(res)
    return {
      success: true,
      status: "✅ Mint Success!"
    };
  } catch (error) {
    return {
      success: false,
      status: "❌ Mint Failed, Please check your wallet for additional info",
    };
  }
};
