import * as React from "react"
import Image from 'react-bootstrap/Image'
import "../styles/namecard.css"

const Namecard = (props) => {

  return (
    <div className="name-card">
      <Image src={props.profile_img} fluid className="img"/>
      <h1 className="name">{props.name}</h1>
      <h2 className="title">{props.title}</h2>
      <p className="desc">{props.description}</p>
    </div>
  )
}

export default Namecard