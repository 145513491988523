export const team_ppl = [
  {
    name: "Tendies",
    title: "Computer Dood",
    bio: "Tendies is an engineer and a Web3 enthusiast. By day he builds software systems for one of the largest companies in this space, and by night well…he’s on a perpetual hunt for tendies. Juicy freshly-fried chicken tendies or sweet r/WallStreetBets style tendies are both accepted.",
  },
  {
    name: "EZ",
    title: "Technology Architect",
    bio: "EZ is a full-time geek passionate about distributed system and block-chain technology. He also dreams of being Captain America one day and terminating haters from the metaverse.",
  },
  {
    name: "Nanla",
    title: "Artist",
    bio: "Nanla is a provocative designer turned artist, who reaches the epitome of her creative potential after a few drinks. Always full of surprises and rarely ever conforming to social norms, her mission is to achieve world peace one NFT at a time.",
  },
  {
    name: "Katsu",
    title: "Creative Development",
    bio: "Katsu is a marketer by trade and a game master at heart, specifically board games. She’s most excited about connecting Fatdoods around the world through riveting activities and exhilarating contests. Her marketing experiences span from banking to global restaurant groups.",
  },
  {
    name: "Wagyu",
    title: "Community manager & strategist ",
    bio: "Wagyu is a veteran project manager and strategist who worked with many of the world's leading financial institutions to bring their strategic vision into life, such as market expansion, M&A, IPO, etc. Bringing Fatdoods’ vision into reality is Wagyu’s next mission. ",
  },
  {
    name: "Toro",
    title: "Project Lead",
    bio: "Toro is a daytime private art dealer and a night-time music producer. He is also a devoted gardener and a fashion nerd. ",
  },
  {
    name: "Steven",
    title: "Policy Neuromancer",
    bio: "Steven is a researcher shedding light on the political economy of emerging technology and inclusive innovation in the techno nexus between digital entrepreneurship and global governmentality. Combing academic understandings with market contextualism, he tries to elucidate and practice the global regulatory system and developmental policy for an evolutionary Fatdoods eco and beyond.",
  },
]

export const faqs = [
  {
    question: `WHEN WILL FATDOODS LAUNCH?`,
    answer: `The Pre Mint phase (WL+OG) starts on March 15th, 2022, at 7 PM PST. 
    The Public mint phase starts on March 16th, 2022, at 7 AM PST (12 hours after Pre Mint)`,
  },
  {
    question: `HOW MUCH IS A FATDOOD NFT?`,
    answer: `Price for OG is at 0.055 ETH and 0.065 for WL. Public Mint price is 0.075 ETH`,
  },
  {
    question: `WHEN CAN I SEE MY FATDOOD NFT?`,
    answer: `Once you have minted a Fatdoods NFT, you will be able to see it by connecting your wallet to OpenSea.`,
  },
  {
    question: `HOW TO MINT A FATDOOD?`,
    answer: `The only way to mint a Fatdood is by connecting your wallet to our official website Fatdoods.io and mint from the website.`,
  },
  {
    question: `FATDOODS 2.0`,
    answer: `Fitdoods sequel reveal upon sellout`,
  },
];

export const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

export const contractAddress = "0x4B2334ab11D1749998af5e7573183473FEaad4e3";
export const prices = {"OG":0.0, "WL":0.0, "":0.0}
export const mintLimit = {"OG":10, "WL":10, "":10}
export const earlyReleastTs = 1647396000;
export const releastTs = 1647439200;
export const mainNetworkId = 1;
export const rinkebyNetworkId = 4;
export const polygonNetworkId = 80001;
export const targetNetworkId = mainNetworkId;
export const projectTotals = 5555;