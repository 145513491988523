import * as React from "react"
import { Nav, Navbar, NavbarBrand, NavbarToggler, Collapse, NavLink} from "reactstrap"
import Image from 'react-bootstrap/Image'
import "bootstrap/dist/css/bootstrap.css";
import "../styles/header.css"
import logo from "../images/logo.png"
import logo_twitter from "../images/logo_twitter.png"
import logo_insta from "../images/logo_insta.png"
import logo_opensea from "../images/logo_opensea.png"
import logo_discord from "../images/logo_discord.png"

const Header = ({ props }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = () => setIsOpen(!isOpen);
  // const wallet = useWallet()
  // const blockNumber = wallet.getBlockNumber()

  return (
    <>
      <Navbar variant="light" expand="lg" sticky="top" light className="dood-navbar">
          <NavbarBrand href="/" className="navbar-left">
            <Image src={logo} width='240px'/>{' '}
          </NavbarBrand>

          <NavbarToggler onClick={toggle} aria-controls="navbarResponsive"/>
          <Collapse isOpen={isOpen} navbar id="navbarResponsive">
            <Nav className="nav-scroll-header mx-auto">
                <NavLink href="#introduction">
                  <div className="header-text-1">
                    <span className="header-stroke-solid">About</span>
                    <span className="header-text-solid" text="About">About</span>
                  </div>
                </NavLink>
                <NavLink href="#roadmap">
                  <div className="header-text-2">
                    <span className="header-stroke-solid">Roadmap</span>
                    <span className="header-text-solid" text="Roadmap">Roadmap</span>
                  </div>
                </NavLink>
                <NavLink href="#team"> 
                  <div className="header-text-3">
                    <span className="header-stroke-solid">Team</span>
                    <span className="header-text-solid" text="Team">Team</span>
                  </div>
                </NavLink>
            </Nav>
      
            <Nav className="ms-auto">
              <NavbarBrand href="https://www.instagram.com/fatdoods/" target="_blank" rel="noreferrer">
                <Image src={logo_insta} width="40px"/>
              </NavbarBrand>
              <NavbarBrand href="https://discord.gg/Fatdoods" target="_blank" rel="noreferrer">
                <Image src={logo_discord} width="40px"/>
              </NavbarBrand>
              <NavbarBrand href="https://twitter.com/FatDoods_NFT" target="_blank" rel="noreferrer">
                <Image src={logo_twitter} width="40px"/>
              </NavbarBrand>
              <NavbarBrand href="https://opensea.io/collection/fatdoods-nft-official" target="_blank" rel="noreferrer">
                <Image src={logo_opensea} width="40px"/>
              </NavbarBrand>
            </Nav>

            {/* <Account wallet={wallet}/> */}
          </Collapse>
        
      </Navbar>
    </>
  )
}

export default Header

